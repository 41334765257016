import React from "react";
import workBanner from "../assets/tri.png";
import Fade from "react-reveal/Slide";
import { Tabs, Tab, Accordion, Col, Row, Badge } from "react-bootstrap";

export default function Faq() {
 const faqData = [
   {
     question: "What services do you offer?",
     answer:
       "We specialize in web development, mobile app development, UI/UX design, software development, software consulting, AI consulting, and custom GPT solutions.",
   },
   {
     question: "How experienced is your team?",
     answer:
       "Our team is composed of seasoned professionals with extensive expertise in diverse technologies, programming languages, and AI-driven solutions.",
   },
   {
     question: "Do you work on projects of all sizes?",
     answer:
       "Yes, we handle projects of all scales, from small business solutions to enterprise-level applications and advanced AI systems.",
   },
   {
     question: "What is your typical project timeline?",
     answer:
       "Timelines depend on the project scope and complexity. We provide clear estimates during the initial consultation.",
   },
   {
     question: "Do you offer ongoing support after project completion?",
     answer:
       "Yes, we provide robust technical support, maintenance, and updates to ensure optimal performance.",
   },
   {
     question: "Can you redesign or update an existing website?",
     answer:
       "Absolutely! We specialize in website redesigns and updates to modernize and optimize your online presence.",
   },
   {
     question: "How do you prioritize security in your projects?",
     answer:
       "We adhere to industry best practices and implement advanced security measures to protect your data and systems.",
   },
   {
     question: "Can you develop apps for iOS and Android platforms?",
     answer:
       "Yes, we have expertise in creating high-quality mobile apps for both iOS and Android platforms.",
   },
   {
     question: "What is your approach to UI/UX design?",
     answer:
       "We focus on crafting intuitive, user-centric designs that deliver exceptional user experiences.",
   },
   {
     question: "Do you provide e-commerce development services?",
     answer:
       "Yes, we build secure, scalable, and feature-rich e-commerce platforms tailored to your needs.",
   },
   {
     question: "Can you integrate third-party APIs into your solutions?",
     answer:
       "Yes, we seamlessly integrate third-party APIs to enhance functionality and improve user experience.",
   },
   {
     question: "Do you offer CMS integration for websites?",
     answer:
       "Yes, we integrate popular CMS platforms, enabling you to easily manage your website's content.",
   },
   {
     question: "How do you price your projects?",
     answer:
       "Our pricing is customized based on the specific requirements and scope of each project.",
   },
   {
     question: "Can you share examples of your previous work?",
     answer:
       "Yes, we can provide a portfolio of our past projects during the initial consultation.",
   },
   {
     question: "How can I start a project with your company?",
     answer:
       "Contact us through our website or reach out directly. We'll discuss your requirements and guide you through the process.",
   },
 ];


  return (
    <div className="faqBody">
      <div className="cardStyles fullWidthHomeCard">
        <Row>
          <Col sm={8}>
            <Fade left>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <h1 className="headingFont">Frequently Asked Questions</h1>
                <p className="mainSubHeading">
                  Welcome to our FAQ page ! Below, we've compiled a list of the
                  most common questions we receive about our web development
                  consulting and the services we offer. If you don't find the
                  answer you're looking for, feel free to reach out to us
                  directly, and we'll be happy to assist you.
                </p>
              </div>
            </Fade>
          </Col>
          <Col sm={4}>
            <Fade right>
              <div
                style={{
                  display: "block",
                  margin: "auto",
                  maxWidth: "250px",
                  paddingTop: "10px",
                }}
              >
                <img src={workBanner} className="CardImages"></img>
              </div>
            </Fade>
          </Col>
        </Row>
      </div>
      <Fade bottom>
        <Tabs
          defaultActiveKey="SoftwareServices"
          id="uncontrolled-tab-example"
          className="mb-3 ContactTabStyle"
          variant="pills"
        >
          {/* -------- Student Tab ------- */}
          <Tab eventKey="SoftwareServices" title="Software Developent">
            <div className="cardStyles">
              <h1
                style={{
                  padding: 20,
                }}
              >
                Digital business transformation
              </h1>
              <Accordion flush>
                {faqData.map((faq, index) => (
                  <Accordion.Item key={index} eventKey={index.toString()}>
                    <Accordion.Header>
                      <h5> Q: {faq.question}</h5>
                    </Accordion.Header>
                    <Accordion.Body className="faqStyle">
                      {" "}
                      A: {faq.answer}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </Tab>

          {/* -------- Employer Tab ------- */}
          <Tab disabled eventKey="CustomApp" title="Internships">
            <div className="cardStyles">
              <h1 style={{ padding: 40, textAlign: "left" }}>
                Resource Handler
              </h1>
              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    I am a graduate and not a student. Can I still get the
                    certification ?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Do you guys help in getting the job ?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    I am a graduate and not a student. Can I still get the
                    certification ?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    Do you guys help in getting the job ?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    I am a graduate and not a student. Can I still get the
                    certification ?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    Do you guys help in getting the job ?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <h1 style={{ padding: 40, textAlign: "left" }}>
                Frequently Asked Questions
              </h1>
              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    I am a graduate and not a student. Can I still get the
                    certification ?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Do you guys help in getting the job ?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    I am a graduate and not a student. Can I still get the
                    certification ?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    Do you guys help in getting the job ?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    I am a graduate and not a student. Can I still get the
                    certification ?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    Do you guys help in getting the job ?
                  </Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Tab>
        </Tabs>
      </Fade>
    </div>
  );
}
