import { Button, ListGroup, Alert, Col, Row } from "react-bootstrap";
import React from "react";
import Fade from "react-reveal/Slide";
import workBanner from "../assets/workBanner.png";
import web from "../assets/web.png";
import hcl from "../assets/HCL_Domino.png";
import tik from "../assets/tik.png";
import ai from "../assets/ai.png";
import mobile from "../assets/mobile.png";
export default function Workshops() {
  return (
    <div className="faqBody">
      <div className="cardStyles fullWidthHomeCard">
        <Row>
          <Col sm={8}>
            <Fade left>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <h1 className="headingFont">Software Solutions</h1>
                <p className="mainSubHeading">
                  Customized Software Solutions for Your Business: Our tailored
                  approach ensures that our software solutions perfectly align
                  with your unique needs. From design to implementation, we
                  deliver efficient and scalable solutions to enhance
                  productivity and achieve your goals. Empower your business
                  with our expertly crafted software applications and
                  cutting-edge technologies.
                </p>
              </div>
            </Fade>
          </Col>
          <Col sm={4}>
            <Fade right>
              <div
                style={{
                  display: "block",
                  margin: "auto",
                  maxWidth: "300px",
                  paddingTop: "10px",
                }}
              >
                <img
                  src={workBanner}
                  alt="sideBanner_Mm"
                  className="CardImages"
                ></img>
              </div>
            </Fade>
          </Col>
        </Row>
      </div>
      <Fade bottom>
        <div className="cardStyles">
          <h1
            style={{
              padding: "30px 60px 30px 60px",
              textAlign: "center",
              color: "#ac5e00",
            }}
          >
            Next-Generation Development: Building Future-Ready Solutions
          </h1>
          <div className="fullWidthHomeCard">
            <Row
              style={{
                marginTop: 20,
              }}
            >
              <Col sm={5}>
                <div
                  className="CustomCard"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Fade left>
                    <div
                      style={{
                        maxWidth: "500px",
                      }}
                    >
                      <img
                        src={mobile}
                        alt="speed"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          display: "block",
                        }}
                      />
                    </div>
                  </Fade>
                </div>
              </Col>
              <Col sm={7}>
                <div className="CustomCard me">
                  <Fade right>
                    <div
                      className="fourCardStyleNoBorder "
                      style={{
                        width: "100%",
                      }}
                    >
                      <h1 className="headingFontM">Mobile App Development</h1>
                      <p
                        className="mainSubHeading"
                        style={{
                          maxWidth: "800px",
                        }}
                      >
                        Empower your business with cutting-edge mobile
                        applications tailored to your unique needs. Our team of
                        skilled developers specializes in creating intuitive and
                        user-friendly mobile apps for both iOS and Android
                        platforms. From ideation to deployment, we follow a
                        customer-centric approach, ensuring seamless integration
                        with your brand and business objectives.
                      </p>
                      <div className="mmListWrapper">
                        <ListGroup variant="flush">
                          <Fade>
                            <ListGroup.Item>
                              <span>
                                <img alt="icon" src={tik} className="mmTik" />
                              </span>
                              Native and Cross-Platform Development
                            </ListGroup.Item>
                          </Fade>
                          <Fade >
                            <ListGroup.Item>
                              <span>
                                <img alt="icon" src={tik} className="mmTik" />
                              </span>
                              User-Centric UI/UX Design
                            </ListGroup.Item>
                          </Fade>
                          <Fade >
                            <ListGroup.Item>
                              <span>
                                <img alt="icon" src={tik} className="mmTik" />
                              </span>
                              Scalable and Secure Solutions
                            </ListGroup.Item>
                          </Fade>
                          <Fade >
                            <ListGroup.Item>
                              <span>
                                <img alt="icon" src={tik} className="mmTik" />
                              </span>
                              Continuous Support and Maintenance
                            </ListGroup.Item>
                          </Fade>
                        </ListGroup>
                      </div>
                      {/* <Button variant="light" className="ctaButton">
                        Get Started
                      </Button> */}
                    </div>
                  </Fade>
                </div>
              </Col>
            </Row>
            {/* __________________________  DCOM CARD __________________________ */}
            <Row
              style={{
                marginTop: 70,
              }}
            >
              <Col sm={7}>
                <div className="CustomCard me">
                  <div
                    className="fourCardStyleNoBorder "
                    style={{
                      width: "100%",
                    }}
                  >
                    <h1 className="headingFontM">Web App Development</h1>
                    <p
                      className="mainSubHeading"
                      style={{
                        maxWidth: "800px",
                      }}
                    >
                      Unlock the full potential of the web with our custom web
                      app development services. Whether it's an e-commerce
                      platform, a corporate portal, or a complex enterprise
                      solution, we deliver high-performance web applications
                      tailored to your requirements. Our agile development
                      process ensures quick delivery without compromising on
                      quality and scalability.
                    </p>
                    <div className="mmListWrapper">
                      <ListGroup variant="flush">
                        <Fade >
                          <ListGroup.Item>
                            <span>
                              <img alt="icon" src={tik} className="mmTik" />
                            </span>
                            Custom Web Application Development.
                          </ListGroup.Item>
                        </Fade>
                        <Fade >
                          <ListGroup.Item>
                            <span>
                              <img alt="icon" src={tik} className="mmTik" />
                            </span>
                            Responsive and Mobile-Friendly Design.
                          </ListGroup.Item>
                        </Fade>
                        <Fade >
                          <ListGroup.Item>
                            <span>
                              <img alt="icon" src={tik} className="mmTik" />
                            </span>
                            Cloud-Based Solutions.
                          </ListGroup.Item>
                        </Fade>
                        <Fade >
                          <ListGroup.Item>
                            <span>
                              <img alt="icon" src={tik} className="mmTik" />
                            </span>
                            Performance Optimization and Security.
                          </ListGroup.Item>
                        </Fade>
                      </ListGroup>
                    </div>
                    {/* <Button variant="light" className="ctaButton">
                      Get Started
                    </Button> */}
                  </div>
                </div>
              </Col>
              <Col sm={5}>
                <div
                  className="CustomCard"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Fade right>
                    <div
                      style={{
                        maxWidth: "500px",
                      }}
                    >
                      <img
                        src={web}
                        alt="speed"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          display: "block",
                        }}
                      />
                    </div>
                  </Fade>
                </div>
              </Col>
            </Row>

            {/* __________________________  App Analyser __________________________ */}
            <Row
              style={{
                marginTop: 70,
              }}
            >
              <Col sm={5}>
                <div
                  className="CustomCard"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Fade left>
                    <div
                      style={{
                        maxWidth: "500px",
                      }}
                    >
                      <img
                        src={ai}
                        alt="speed"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          display: "block",
                        }}
                      />
                    </div>
                  </Fade>
                </div>
              </Col>
              <Col sm={7}>
                <div className="CustomCard me">
                  <Fade right>
                    <div
                      className="fourCardStyleNoBorder "
                      style={{
                        width: "100%",
                      }}
                    >
                      <h1 className="headingFontM">AI Applications</h1>
                      <p
                        className="mainSubHeading"
                        style={{
                          maxWidth: "800px",
                        }}
                      >
                        Experience the future of technology with our AI-powered
                        applications. Our expertise in artificial intelligence
                        and machine learning allows us to develop smart and
                        efficient solutions that automate processes, analyze
                        data, and provide valuable insights. From chatbots to
                        recommendation engines, we harness the power of AI to
                        elevate your business to new heights.
                      </p>
                      <div className="mmListWrapper">
                        <ListGroup variant="flush">
                          <Fade >
                            <ListGroup.Item>
                              <span>
                                <img alt="icon" src={tik} className="mmTik" />
                              </span>
                              AI-Driven Automation
                            </ListGroup.Item>
                          </Fade>
                          <Fade >
                            <ListGroup.Item>
                              <span>
                                <img alt="icon" src={tik} className="mmTik" />
                              </span>
                              Natural Language Processing (NLP)
                            </ListGroup.Item>
                          </Fade>
                          <Fade >
                            <ListGroup.Item>
                              <span>
                                <img alt="icon" src={tik} className="mmTik" />
                              </span>
                              Data Analytics and Predictive Models
                            </ListGroup.Item>
                          </Fade>
                          <Fade >
                            <ListGroup.Item>
                              <span>
                                <img alt="icon" src={tik} className="mmTik" />
                              </span>
                              Personalized User Experiences
                            </ListGroup.Item>
                          </Fade>
                        </ListGroup>
                      </div>
                    </div>
                  </Fade>
                </div>
              </Col>
            </Row>
          </div>
          <p className="mainSubHeading">
            At RichLabs, we are committed to delivering best-in-class software
            solutions that drive your business forward. Our expertise in mobile
            app development, web app development, and AI applications ensures
            that you stay ahead in the digital landscape. With a focus on
            innovation, customer-centricity, and cutting-edge technology, we
            bring your visions to life. Let our team of skilled professionals
            empower your success with tailored solutions that exceed
            expectations. Embrace the future of technology with RichLabs by your
            side. Contact us now and embark on a transformative journey toward
            unparalleled growth and success. Together, we'll shape a brighter
            future for your business.
          </p>
        </div>
      </Fade>
    </div>
  );
}
