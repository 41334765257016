import { React, useState } from "react";
import Fade from "react-reveal/Fade";
import grow from "../assets/grow.png";
import customAppIcon from "../assets/rivitRing.png";
import hcl from "../assets/HCL_Domino.png";
import YchooseRL from "../assets/YchooseRL.png";
import icon from "../assets/verified.gif";
import rightArrow from "../assets/rightArrow.gif";
import blogImg1 from "../assets/blogImg1.jpg";
import blogImg2 from "../assets/blogImg2.png";
import mst from "../assets/mst.png";
import blogImg3 from "../assets/blogImg3.png";
import ind from "../assets/ind.png";
import cnd from "../assets/cnd.png";
import { useHistory } from "react-router-dom";
import clients from "../assets/bph.jpg";

import { Button, ListGroup, Row, Col } from "react-bootstrap";

export default function FirstBlock() {
  const history = useHistory();
  const [enter, setEnter] = useState(false);

  function MouseOver(event) {
    setEnter(true);
  }

  function MouseOut(event) {
    setEnter(false);
  }
  return (
    <>
      {/* First Row */}
      <div className="parentOfOneCard">
        <div className="cardStyles fullWidthHomeCard">
          <Row style={{ margin: "50px 0px 10px 0px" }}>
            <Col sm={8}>
              <Fade left>
                <div className="firstBlock">
                  <h1 className="headingFont">
                    Custom Product & <br />
                    Software Development Tailored for your Success.
                  </h1>
                  <p className="mainSubHeading">
                    Helping you rapidly build and launch enterprise-grade,
                    business, and customer-facing apps using low-code
                    development. Witness immediate business value with 10x
                    faster development. <br /> We follow the culture of constant
                    learning and improvement to provide the best solutions to
                    clients.
                  </p>
                  {/* <p className="mainTitle">
                    Ready to take the headache out of your tech project?
                  </p> */}

                  <Button
                    onMouseOver={MouseOver}
                    onMouseOut={MouseOut}
                    variant="light"
                    className="ctaButton"
                  >
                    Let's talk Strategy
                    {/* {enter && (
                      <img
                        height="30px"
                        width="90px"
                        alt="CustomApp"
                        src={rightArrow}
                        className="CardImageIconHomePage"
                      />
                    )} */}
                  </Button>
                  <div>
                    <div style={{ marginTop: "45px" }}></div>
                  </div>
                </div>
              </Fade>
            </Col>
            <Col sm={4}>
              <Fade right>
                <div
                  style={{
                    display: "block",
                    margin: "auto",
                    maxWidth: "300px",
                  }}
                >
                  <img src={grow} alt="speed" className="CardImages" />
                </div>
              </Fade>
            </Col>
          </Row>

          <div className="CustomCardRow">
            <div className="CustomCard">
              <Fade bottom>
                <div className="fourCardStyle cardFixedHeight">
                  <img
                    height="150px"
                    width="150px"
                    alt="CustomApp"
                    src={cnd}
                    className="CardImageIconHomePage"
                  />
                  <h3 className="subsCardHeading">
                    Conceptualization and Design
                  </h3>
                  <p className="mainSubHeadingSm">
                    Requirements gathering, Ideation and conceptualization, User
                    Interface (UI) and User Experience (UX) design, Prototyping
                  </p>

                  <Button
                    onClick={() => history.push("/workshops")}
                    variant="outline-warning"
                  >
                    Learn More
                  </Button>
                </div>
              </Fade>
            </div>
            {/* ------------------- 2ndCard -------------------*/}
            <div className="CustomCard">
              <Fade bottom>
                <div className="fourCardStyle cardFixedHeight">
                  <img
                    height="150px"
                    width="150px"
                    alt="CustomApp"
                    src={ind}
                    className="CardImageIconHomePage"
                  />
                  <h3 className="subsCardHeading">
                    Integration and Development
                  </h3>
                  <p className="mainSubHeadingSm">
                    Software development, Quality assurance and testing,
                    Seamless deployment and integration with existing systems,
                    databases etc.
                  </p>
                  <Button
                    onClick={() => history.push("/hcldomino")}
                    // className="btnColor"
                    variant="outline-warning"
                  >
                    Learn More
                  </Button>
                </div>
              </Fade>
            </div>
            <div className="CustomCard">
              <Fade bottom>
                <div className="fourCardStyle cardFixedHeight">
                  <img
                    height="150px"
                    width="150px"
                    alt="CustomApp"
                    src={mst}
                    className="CardImageIconHomePage"
                  />
                  <h3 className="subsCardHeading">
                    Maintenance, Support & Training
                  </h3>
                  <p className="mainSubHeadingSm">
                    Providing technical support, expert guidance,
                    troubleshooting to keep the software up-to-date and running
                    smoothly.
                  </p>
                  <Button
                    onClick={() => history.push("/customapp")}
                    // className="btnColor"
                    variant="outline-warning"
                  >
                    Contact Us
                  </Button>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>

      {/* ---------------------Why Rich ? -----------------------------------  */}
      <Fade bottom>
        <Row
          style={{
            marginTop: 50,
          }}
        >
          <Col sm={5}>
            <div
              className="CustomCard"
              style={{
                display: "flex",
                justifyContent: "left",
              }}
            >
              <Fade bottom>
                <div
                  style={{
                    maxWidth: "700px",
                  }}
                >
                  <img
                    src={YchooseRL}
                    alt="speed"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      display: "block",
                      marginTop: "15px",
                      borderRadius: "8px",
                    }}
                  />
                </div>
              </Fade>
            </div>
          </Col>
          <Col sm={7}>
            <div className="CustomCard me">
              <Fade bottom>
                <div
                  className="fourCardStyleNoBorder cardStyles"
                  style={{
                    width: "100%",
                  }}
                >
                  <span className="subsCardHeading headingFont">
                    Why Rich Labs for your Product ?
                  </span>
                  <p className="bodyTxt">
                    Choosing us means partnering with a dedicated team that
                    embraces your vision, navigates the complexities of product
                    development, and ensures your idea evolves into a successful
                    reality. With our expertise in software development,
                    comprehensive support, and innovation-driven approach, we
                    are the ideal collaborator to transform your concepts into a
                    remarkable product that leaves a lasting impact in the
                    market.
                    <br />
                    Throughout the entire process, we provide comprehensive
                    support, assisting you with technical expertise,
                    troubleshooting, and guidance to keep your software
                    up-to-date and running seamlessly.
                  </p>
                  <div className="mmListWrapper">
                    <ListGroup variant="flush">
                      <Fade>
                        <ListGroup.Item>
                          <span>
                            <img alt="icon" src={icon} className="mmTik" />
                          </span>
                          Expert Brainstorming and Conceptualization
                        </ListGroup.Item>
                      </Fade>
                      <Fade>
                        <ListGroup.Item>
                          <span>
                            <img alt="icon" src={icon} className="mmTik" />
                          </span>
                          Comprehensive Product Journey
                        </ListGroup.Item>
                      </Fade>
                      <Fade>
                        <ListGroup.Item>
                          <span>
                            <img alt="icon" src={icon} className="mmTik" />
                          </span>
                          Standardized Technology Stack
                        </ListGroup.Item>
                      </Fade>

                      <Fade>
                        <ListGroup.Item>
                          <span>
                            <img alt="icon" src={icon} className="mmTik" />
                          </span>
                          Client-Centric Approach
                        </ListGroup.Item>
                      </Fade>
                    </ListGroup>
                  </div>
                  <Button variant="light" className=" ctaButton">
                    Get Started
                  </Button>
                </div>
              </Fade>
            </div>
          </Col>
        </Row>
      </Fade>
      {/* ---------------- Helping Our Customer Section  ---------------------- */}
      <Fade bottom>
        <div
          className="fullWidthHomeCardX"
          style={{
            // backgroundImage: "linear-gradient(#05213a, #05213aeb)",
            marginTop: 50,
          }}
        >
          <h1
            className="headingFont"
            style={{
              color: "#000",
              display: "block",
              alignSelf: "center",
              marginTop: 20,
            }}
          >
            Our Process
          </h1>
          <img
            src={clients}
            height="auto"
            alt="clients"
            className="clientImage"
          />
        </div>
      </Fade>
      {/* Rivit By Numbers*/}

      <Fade bottom>
        <div
          className="fullWidthHomeCard"
          style={{
            marginTop: 100,
          }}
        >
          <h1
            className="headingFont"
            style={{
              color: "#000",
              display: "block",
              alignSelf: "center",
              padding: 20,
            }}
          >
            From Vision to Reality
          </h1>
          <div className="CustomCardRow">
            <div className="CustomCard">
              <Fade left>
                <div className="fourCardStyleNoBorder cardFixedHeightSM">
                  <h1 className="subsCardHeading">100%</h1>
                  <h3> Customized Solutions</h3>
                </div>
              </Fade>
            </div>
            <div className="CustomCard">
              <Fade left>
                <div className="fourCardStyleNoBorder cardFixedHeightSM">
                  <h1 className="subsCardHeading">50+</h1>
                  <h3>Projects Completed</h3>
                </div>
              </Fade>
            </div>
            <div className="CustomCard">
              <Fade right>
                <div className="fourCardStyleNoBorder cardFixedHeightSM">
                  <h1 className="subsCardHeading">35+</h1>
                  <h3>Years of Combined Experience</h3>
                </div>
              </Fade>
            </div>
            <div className="CustomCard">
              <Fade right>
                <div className="fourCardStyleNoBorder cardFixedHeightSM">
                  <h1 className="subsCardHeading">20k+</h1>
                  <h3> Lines of Code Written</h3>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </Fade>
      {/* ------------------ Insights --------------------------------*/}
      <Fade bottom>
        <div
          className="fullWidthHomeCard"
          style={{
            marginTop: 100,
          }}
        >
          <h1
            className="headingFont"
            style={{
              color: "#000",
              display: "block",
              alignSelf: "center",
              padding: 20,
            }}
          >
            Insights
          </h1>
          <div className="CustomTwoCardRow">
            <Fade bottom>
              <div className="CustomCard">
                <div className="fourCardStyleNoBorder">
                  <img
                    className="CardImageBlog"
                    height="158px"
                    width="320px"
                    alt="CustomApp"
                    src={blogImg1}
                  />
                  <h5 className="subsCardHeading">Ebook (PDF)</h5>
                  <p>The Ultimate Guide to Mobile App Development</p>
                  <Button
                    onClick={() =>
                      window.open(
                        "https://baou.edu.in/assets/pdf/PGDCA203_slm.pdf",
                        "_blank"
                      )
                    }
                    variant="outline-primary"
                    size="sm"
                  >
                    Go To Resource
                  </Button>
                </div>
              </div>
            </Fade>
            <Fade bottom>
              <div className="CustomCard">
                <div className="fourCardStyleNoBorder">
                  <img
                    className="CardImageBlog"
                    height="158px"
                    width="320px"
                    alt="CustomApp"
                    src={blogImg2}
                  />
                  <h5 className="subsCardHeading">
                    Software development handbook (PDF)
                  </h5>
                  <p>Transforming for the digital age - McKinsey research</p>
                  <Button
                    onClick={() =>
                      window.open(
                        "https://www.mckinsey.com/~/media/McKinsey/Industries/Technology%20Media%20and%20Telecommunications/High%20Tech/Our%20Insights/Software%20Development%20Handbook%20Transforming%20for%20the%20digital%20age/Software%20Development%20Handbook%20Transforming%20for%20the%20digital%20age.pdf",
                        "_blank"
                      )
                    }
                    variant="outline-warning"
                    size="sm"
                  >
                    Read More
                  </Button>
                </div>
              </div>
            </Fade>

            <Fade bottom>
              <div className="CustomCard">
                <div className="fourCardStyleNoBorder">
                  <img
                    className="CardImageBlog"
                    height="158px"
                    width="320px"
                    alt="CustomApp"
                    src={blogImg3}
                  />
                  <h5 className="subsCardHeading">
                    Reinforcement Learning: An Introduction
                  </h5>
                  <p>
                    Fundamentals of reinforcement learning - Richard S. Sutton
                  </p>
                  <Button
                    onClick={() =>
                      window.open(
                        "http://incompleteideas.net/book/bookdraft2018jan1.pdf",
                        "_blank"
                      )
                    }
                    variant="outline-success"
                    size="sm"
                  >
                    Read More
                  </Button>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </Fade>
    </>
  );
}
