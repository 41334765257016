import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Components/Header";
import Slide from "react-reveal/Slide";
import FirstBlock from "./Components/FirstBlock";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ContactUs from "./Components/ContactUs";
import Faq from "./Components/Faq";
import Workshops from "./Components/Workshops";
import Careers from "./Components/Careers";
import CustomApp from "./Components/CustomApp";
import NotFound from "./Components/NotFound";
import StudentPortal from "./StudentPortal/StudentPortal";
import LoginSignup from "./StudentPortal/LoginSignup";
import Footer from "./Components/Footer";

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <div className="App">
        <Slide top>
          <Header />
        </Slide>
        <Switch>
          <Route path="/" exact component={FirstBlock}></Route>
          <Route path="/contactUs" component={ContactUs}></Route>
          <Route path="/faqs" component={Faq}></Route>
          <Route path="/softwaresolution" component={Workshops}></Route>
          <Route path="/careers" component={Careers}></Route>
          <Route path="/customapp" component={CustomApp}></Route>
          <Route path="*" component={NotFound}></Route>
        </Switch>
        <Slide bottom>
          <Footer />
        </Slide>
      </div>
    </Router>
  );
}

export default App;
