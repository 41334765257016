import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import rlLogo from "../assets/logo_richlabs.png";
import LoginSignup from "../StudentPortal/LoginSignup";

export default function Header() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const [show, setshow] = useState(false);

  return (
    <div className="headerStyles">
      <LoginSignup onHide={() => setshow(false)} show={show}></LoginSignup>
      <Navbar
        className="navBarStyles ml-auto"
        sticky="top"
        collapseOnSelect
        expand="md"
        bg="light"
        variant="light"
      >
        <Container className="navBarContainer">
          <Navbar.Brand href="/">
            <img
              alt="logo"
              src={rlLogo}
              width="50"
              height="45"
              className="logo d-inline-block"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Nav.Link
            style={{
              color: "white",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span
              href="/"
              className="two-color-text"
              style={{
                fontSize: "36px",
                fontWeight: 500,
              }}
            >
              RichLabs.io
            </span>
          </Nav.Link>

          <Navbar.Collapse
            className="justify-content-end"
            id="responsive-navbar-nav"
          >
            <Nav className="ml-auto">
              <NavDropdown title="Solutions" id="solutions-dropdown">
                <NavDropdown.Item as={Link} to={"/customapp"}>
                  Custom Applications
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to={"/softwaresolution"}>
                  Software Solutions
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} to={"/faqs"}>
                Frequently Asked
              </Nav.Link>
              <Nav.Link as={Link} to={"/contactUs"}>
                Get in Touch
              </Nav.Link>
              <Nav.Link as={Link} to={"/careers"}>
                Join Our Team
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
